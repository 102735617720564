import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField
} from '@material-ui/core';

const GroupInfoForm = ({
  t,
  state,
  dispatch,
  validationEmail,
  validatePhone
}) => {
  const organizationTypes = [
    `${t('group.form.org_type.association')}`,
    `${t('group.form.org_type.works_council')}`,
    `${t('group.form.org_type.firm')}`,
    `${t('group.form.org_type.school')}`,
    `${t('group.form.org_type.tourism_professional')}`,
    `${t('group.form.org_type.other')}`
  ];

  const visitTypes = [
    `${t('group.form.visit_type.intermezzo')}`,
    `${t('group.form.visit_type.belle_epoque')}`,
    `${t('group.form.visit_type.work_of_art')}`,
    `${t('group.form.visit_type.visit_cycle_1')}`,
    `${t('group.form.visit_type.visit_cycle_2')}`,
    `${t('group.form.visit_type.visit_cycle_3')}`,
    `${t('group.form.visit_type.visit_surprise')}`
  ];

  const handleInputChange = (name) => (event) => {
    const errors = [];
    const { value } = event ? event.target || { target: {} } : '';

    switch (name) {
      case 'email':
        dispatch({
          group: {
            ...(state.group || {}),
            email: value
          }
        });
        if (!validationEmail(value)) {
          errors.push('email_format');
        }
        break;
      case 'name':
        dispatch({
          group: {
            ...(state.group || {}),
            name: value
          }
        });
        break;
      case 'address':
        dispatch({
          group: {
            ...(state.group || {}),
            address: value
          }
        });
        break;
      case 'estimated_visitors_number':
        dispatch({
          group: {
            ...(state.group || {}),
            estimated_visitors_number: value
          }
        });
        break;
      case 'desired_dates':
        dispatch({
          group: {
            ...(state.group || {}),
            desired_dates: value
          }
        });
        break;
      case 'desired_hour':
        dispatch({
          group: {
            ...(state.group || {}),
            desired_hour: value
          }
        });
        break;
      case 'billing_address':
        dispatch({
          group: {
            ...(state.group || {}),
            billing_address: value
          }
        });
        break;
      case 'phone':
        dispatch({
          group: {
            ...(state.group || {}),
            phone: value
          }
        });
        if (!validatePhone(value)) {
          errors.push('phone_format');
        }
        break;
      case 'desired_language':
        dispatch({
          group: {
            ...(state.group || {}),
            desired_language: value
          }
        });
        break;
      case 'visitors_profile':
        dispatch({
          group: {
            ...(state.group || {}),
            visitors_profile: value
          }
        });
        break;
      default:
        break;
    }
    dispatch({
      errors_validation_group: errors
    });
  };

  const isBillingAddress = (value) => {
    dispatch({ is_billing_address: value });
  };

  const handleTypeChange = (value) => {
    dispatch({
      group: {
        ...(state.group || {}),
        organization_type: value
      }
    });
  };

  const handleVisitChange = (value) => {
    dispatch({
      group: {
        ...(state.group || {}),
        visit_theme: value
      }
    });
  };

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' }
      }}
      noValidate
      autoComplete="off"
      className="group-info-form-wrapper"
    >
      <FormControl>
        <div className="group-form-fields">
          <div className="group-form-row">
            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
              <FormControl required>
                <InputLabel
                  id="demo-simple-select-label"
                  error={
                    state?.errors_validation_group?.includes(
                      'organization_type'
                    ) && !state.group.organization_type
                  }
                >
                  {t('group.form.organization_type')}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  style={{ minWidth: 225 }}
                  value={state.group.organization_type || ''}
                  onChange={(event) => handleTypeChange(event.target.value)}
                >
                  {(organizationTypes || []).map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <TextField
              required
              error={
                state?.errors_validation_group?.includes('name') &&
                !state.group.name
              }
              label={t('group.form.name')}
              onChange={handleInputChange('name')}
              value={state.group.name || ''}
            />
          </div>
          <div className="group-form-row">
            <TextField
              required
              error={
                state?.errors_validation_group?.includes('address') &&
                !state.group.address
              }
              label={t('group.form.address')}
              onChange={handleInputChange('address')}
              value={state.group.address || ''}
            />
            <TextField
              required
              error={
                (state?.errors_validation_group?.includes('email') &&
                  !state.group.email) ||
                state?.errors_validation_group?.includes('email_format')
              }
              helperText={
                (state.errors_validation_group || []).includes(
                  'email_format'
                ) && t('errors.email_format')
              }
              label={t('group.form.email')}
              onChange={handleInputChange('email')}
              value={state.group.email || ''}
            />
          </div>
          <div className="group-form-row">
            <TextField
              required
              error={
                state?.errors_validation_group?.includes('phone') &&
                !state.group.phone
              }
              label={t('group.form.phone')}
              placeholder={t('visitors.form.free_field')}
              onChange={handleInputChange('phone')}
              value={state.group.phone || ''}
              helperText={
                state.errors_validation_group?.includes('phone_format') &&
                t('errors.phone_format')
              }
            />
            <TextField
              required
              error={
                state?.errors_validation_group?.includes(
                  'estimated_visitors_number'
                ) && !state.group.estimated_visitors_number
              }
              label={t('group.form.estimated_visitors_number')}
              onChange={handleInputChange('estimated_visitors_number')}
              value={state.group.estimated_visitors_number || ''}
            />
          </div>
          <div className="group-form-row">
            <TextField
              required
              error={
                state?.errors_validation_group?.includes('desired_dates') &&
                !state.group.desired_dates
              }
              label={t('group.form.desired_dates')}
              onChange={handleInputChange('desired_dates')}
              placeholder={t('visitors.form.free_field')}
              value={state.group.desired_dates || ''}
            />
            <TextField
              required
              error={
                state?.errors_validation_group?.includes('desired_hour') &&
                !state.group.desired_hour
              }
              label={t('group.form.desired_hour')}
              onChange={handleInputChange('desired_hour')}
              placeholder={t('visitors.form.free_field')}
              value={state.group.desired_hour || ''}
            />
          </div>
          <div className="group-form-row">
            <TextField
              required
              error={
                state?.errors_validation_group?.includes('desired_language') &&
                !state.group.desired_language
              }
              label={t('group.form.desired_language')}
              onChange={handleInputChange('desired_language')}
              placeholder={t('visitors.form.free_field')}
              value={state.group.desired_language || ''}
            />
            <TextField
              required
              error={
                state?.errors_validation_group?.includes('visitors_profile') &&
                !state.group.visitors_profile
              }
              label={t('group.form.visitors_profile')}
              onChange={handleInputChange('visitors_profile')}
              placeholder={t('visitors.form.free_field')}
              value={state.group.visitors_profile || ''}
            />
          </div>
          <div className="group-form-row">
            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
              <FormControl required>
                <InputLabel
                  id="demo-simple-select-label"
                  error={
                    state?.errors_validation_group?.includes('visit_theme') &&
                    !state.group.visit_theme
                  }
                >
                  {t('group.form.visit_theme')}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  style={{ minWidth: 225 }}
                  value={state.group.visit_theme || ''}
                  onChange={(event) => handleVisitChange(event.target.value)}
                >
                  {(visitTypes || []).map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="billing-address-form-row">
            <span>{t('group.form.is_billing_address')}</span>
            <RadioGroup
              required
              row
              defaultValue="no"
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="yes"
                control={
                  <Radio
                    checked={state.is_billing_address}
                    onClick={() => isBillingAddress(true)}
                    style={{ color: 'rgba(0, 0, 0, 0.7)' }}
                  />
                }
                label={t('group.form.is_billing_address_yes')}
              />
              <FormControlLabel
                value="no"
                control={
                  <Radio
                    onClick={() => isBillingAddress(false)}
                    style={{ color: 'rgba(0, 0, 0, 0.7)' }}
                  />
                }
                label={t('group.form.is_billing_address_no')}
              />
            </RadioGroup>
          </div>
          {state.is_billing_address && (
            <div className="group-form-row">
              <TextField
                label={t('group.form.billing_address')}
                onChange={handleInputChange('billing_address')}
                value={state.group.billing_address || ''}
              />
            </div>
          )}
        </div>
      </FormControl>
    </Box>
  );
};

export default GroupInfoForm;

GroupInfoForm.propTypes = {
  t: PropTypes.func.isRequired,
  state: PropTypes.shape({
    group: PropTypes.shape({
      organization_type: PropTypes.string,
      name: PropTypes.string,
      address: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
      estimated_visitors_number: PropTypes.string,
      desired_dates: PropTypes.string,
      desired_hour: PropTypes.string,
      desired_language: PropTypes.string,
      visitors_profile: PropTypes.string,
      visit_theme: PropTypes.string,
      billing_address: PropTypes.string
    }),
    is_billing_address: PropTypes.bool,
    errors_validation_group: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  validationEmail: PropTypes.func,
  validatePhone: PropTypes.func
};
