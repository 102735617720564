import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Modal } from '@material-ui/core';
import { navigate } from 'gatsby';
import { MdClear } from 'react-icons/md';

const ModalConfirmation = ({ t, visible, dispatch }) => {
  const onClose = () => {
    dispatch({ visible: false });
    navigate('/reservation/');
  };

  return (
    <Modal
      disableEscapeKeyDown
      open={visible}
      onClose={() => onClose()}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
      className="modal small"
    >
      <div className="modal-content">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <h2>{t('group.confirm.success')}</h2>
          <IconButton onClick={onClose}>
            <MdClear />
          </IconButton>
        </div>

        <p>{t('group.confirm.success-text')}</p>
      </div>
    </Modal>
  );
};

export default ModalConfirmation;

ModalConfirmation.propTypes = {
  t: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  dispatch: PropTypes.func.isRequired
};

ModalConfirmation.defaultProps = {
  visible: undefined
};
